<template>
  <div>
    <h1>Check My Charter</h1>
    <p><a href="https://fishthewahoo.com">Back To fishthewahoo.com</a></p>

    <div
      v-if="isTableLoading"
      class="text-center pt-3"
    >
      <b-spinner label="Loading..." />
    </div>

    <template v-else>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="charters"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Common -->
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-end flex-wrap">
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </template>
      </vue-good-table>
    </template>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import { toastFailure } from '@/libs/toastification'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BSpinner,
  },
  data() {
    return {
      isTableLoading: true,
      searchTerm: '',
      columns: [
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Boat',
          field: 'boat',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Time',
          field: 'time',
        },
        {
          label: 'Reservation #',
          field: 'id',
          type: 'number',
        },
      ],
      pageLength: 25,
    }
  },
  computed: {
    ...mapGetters({
      charters: 'bookings/charters',
    }),
  },
  async mounted() {
    await this.loadCharters()
      .then(() => { this.isTableLoading = false })
      .catch(() => { toastFailure('Something went wrong.') })
  },
  methods: {
    ...mapActions({
      loadCharters: 'bookings/loadCharters',
    }),
  },
}
</script>

<style>

</style>
